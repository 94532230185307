.gateway {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 5rem 0 4rem 0;
  position: relative;
  z-index: 3;
}
.gateway::before {
  position: absolute;
  width: 100%;
  height: 8rem;
  left: 0;
  right: 0;
  bottom: 0;
  top: -6rem;
  content: "";
  background: #13122b;
  transform-origin: top right;
  transform: skewY(-4deg);
  z-index: 2;
}
.gateway-body {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: 1px solid red; */
}

.gateway-l {
  /* border: 1px green solid; */
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2rem;
}

.gateway-l .big-txt {
  display: flex;
  flex-direction: column;
}
.gateway-l .big-txt > span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 53px;
  letter-spacing: 0.01em;

  color: #000000;
}

.gateway-l .big-txt .special-gateway-text {
  color: #f8ad01;
}

.gateway-l .small-txt {
  width: 331px;
}
.gateway-l .small-txt > span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  /* or 200% */
  letter-spacing: 0.01em;

  /* Grey Scale light/1 */
  color: #adaeb6;
}
.gateway-r .iphone13 {
  width: 350px;
  height: 553.21px;
  /* box-shadow: 28px 23px 58px rgba(0, 0, 0, 0.25); */
}

.gateway-r .iphone13 > img {
  width: 100%;
  height: 100%;
}

.gateway-ractangel1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  background-color: #ffffff;
  /* border: 1px solid salmon; */
  box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
  border-radius: 8.38597px;
  width: 350px;
  height: 86px;
  top: 4.2rem;
  right: 13.5rem;
  z-index: 1;
}

.gateway-footer a{
    text-decoration: none;
}
.gateway-book {
  width: 83px;
  height: 42.2px;
}

.gateway-percent {
  width: 36.37px;
  height: 40.09px;
}

.gateway-reward {
  width: 80.04px;
  height: 57px;
}
.gateway-ractangel2 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  background-color: #ffffff;
  /* border: 1px solid salmon; */
  box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
  border-radius: 8.38597px;
  width: 350px;
  height: 86px;
  top: -7.4rem;
  right: -7rem;
  z-index: 1;
}

.gateway-oyo {
  width: 83px;
  height: 34.2px;
}

.gateway-percent2 {
  width: 36.37px;
  height: 40.09px;
}

.gateway-reward2 {
  width: 80.05px;
  height: 57px;
}
/*gateway footer */

.gateway-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  justify-content: center;
}

.gateway-footer .footer-head {
  display: flex;
  flex-direction: column;
}
.gateway-footer .footer-head > span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #000000;
}

.gateway-footer .footer-btn  {
  background: #2e2b69;
  border-radius: 52px;
  width: 223px;
  height: 57px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
    cursor: pointer;
  color: #ffffff;
}

.gateway-footer .footer-btn a{
    text-decoration: none;
    color: #ffffff;
}
.gateway-footer .footer-btn  .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 95%;
    left: 38%;
    z-index: 9;
    opacity: 3;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }

  @media screen and (max-width:1300px) {
    .gateway-footer .footer-btn  .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 95%;
        left: 37%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
  }

@media screen and (max-width: 1150px) {
  .gateway-ractangel2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 350px;
    height: 86px;
    top: -3.4rem;
    right: 2rem;
    z-index: 1;
  }
  .gateway-footer .footer-btn  .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 95%;
    left: 36%;
    z-index: 9;
    opacity: 3;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
}

@media screen and (max-width:1060px) {
    .gateway-footer .footer-btn  .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 95%;
        left: 35%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
  }
@media screen and (max-width:1000px) {
    .gateway-footer .footer-btn  .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 95%;
        left: 34%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
  }
@media screen and (max-width: 900px) {
  .gateway-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
    padding: 0 2rem;
    gap: 1rem;
  }
  .gateway-footer .footer-btn  .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 95%;
    left: 32%;
    z-index: 9;
    opacity: 3;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
}

@media screen and (max-width:850px) {
    .gateway-footer .footer-btn  .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 95%;
        left: 31%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
  }
@media screen and (max-width:800px) {
    .gateway-footer .footer-btn  .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 95%;
        left: 30%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
  }
@media screen and (max-width: 750px) {
  .gateway {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    padding: 10rem 0 0 0;
    gap: 5rem;
  }

  .gateway-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid red; */
    padding: 0 0 0 1rem;
    gap: 0rem;
  }
  .small-txt {
    position: relative;
    top: 45rem;
    left: 1rem;
  }
  .gateway-l {
    /* border: 1px green solid; */
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
  .gateway-l .big-txt {
    display: flex;
    flex-direction: column;
  }
  .gateway-l .big-txt > span {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.01em;

    /* primary/3 */
    color: #000000;
  }
  .gateway-l .small-txt > span {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;

    /* or 200% */
    text-align: center;
    letter-spacing: 0.01em;

    /* Grey Scale light/1 */
    color: #adaeb6;
  }
  .gateway-r {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    position: relative;
    top: -10rem;
  }

  .gateway-r .iphone13 {
    padding-left: 2.5rem;
    width: 350px;
    height: 553.21px;
    /* box-shadow: 28px 23px 58px rgba(0, 0, 0, 0.25); */
  }

  .gateway-ractangel1 {
    display: flex;
    /* padding-right: 1rem; */
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 310px !important;
    height: 76.3px !important;
    height: 86px;
    top: 10.5rem;
    right: 10rem;
    z-index: 1;
  }

  .gateway-book {
    width: 70px;
    height: 30px;
  }
  .gateway-percent {
    width: 32.27px;
    height: 35.57px;
  }
  .gateway-reward {
    width: 70.58px;
    height: 50.6px;
  }

  .gateway-ractangel2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* padding-left: 1rem; */
    gap: 1rem;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 310.53px !important;
    height: 76.3px !important;
    height: 86px;
    top: -3.4rem;
    right: -5rem;
    z-index: 1;
  }
  .gateway-oyo {
    width: 70px;
    height: 30px;
  }
  .gateway-percent2 {
    width: 32.27px;
    height: 35.57px;
  }
  .gateway-reward2 {
    width: 70.58px;
    height: 50.6px;
  }
}

@media screen and (max-width: 550px) {
  .gateway {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    padding: 8rem 0 0 0;
    gap: 5rem;
  }

  .small-txt {
    position: relative;
    top: 45rem;
    left: -0.5rem;
  }
  .gateway-l .big-txt > span {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.01em;

    /* primary/3 */
    color: #000000;
  }
  .gateway-ractangel1 {
    display: flex;
    padding-left: 2rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 310px !important;
    height: 76.3px !important;
    height: 86px;
    top: 10.5rem;
    right: 10rem;
    z-index: 1;
  }

  .gateway-ractangel2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 2rem;
    gap: 1rem;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 310.53px !important;
    height: 76.3px !important;
    height: 86px;
    top: -3.4rem;
    right: -5rem;
    z-index: 1;
  }
}
@media screen and (max-width: 500px) {
  .gateway-ractangel1 {
    display: flex;
    padding-right: 1rem;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 310px !important;
    height: 76.3px !important;
    height: 86px;
    top: 10.5rem;
    right: 10rem;
    z-index: 1;
  }

  .gateway-ractangel2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
    gap: 1rem;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 310.53px !important;
    height: 76.3px !important;
    height: 86px;
    top: -3.4rem;
    right: -5rem;
    z-index: 1;
  }
  .gateway-footer .footer-head > span {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #000000;
  }
  .gateway-footer .footer-btn > a > button {
    background: #2e2b69;
    border-radius: 52px;
    width: 223px;
    height: 57px;
    border: none;
  }
}

@media screen and (max-width: 350px) {
  .small-txt {
    position: relative;
    top: 45rem;
    left: 0.7rem;
  }
  .gateway-l {
    /* border: 1px green solid; */
    display: flex;
    flex-direction: column;
    padding: 0 3rem 0 2rem;
    gap: 2rem;
  }
  .gateway-l .big-txt {
    padding: 0 1rem 0 1rem;
  }
  .gateway-l .big-txt > span {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.01em;

    /* primary/3 */
    color: #000000;
  }
  .gateway-l .small-txt {
    padding: 0 3rem 0 2rem;
  }
  .gateway-l .small-txt > span {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    /* text-align: center; */
    /* or 200% */
    text-align: center;
    letter-spacing: 0.01em;

    /* Grey Scale light/1 */
    color: #adaeb6;
  }
  .gateway-ractangel2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 1rem;
    gap: 1rem;
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid salmon; */
    box-shadow: 0px 2.09649px 23.5856px rgba(57, 57, 57, 0.09);
    border-radius: 8.38597px;
    width: 310.53px !important;
    height: 76.3px !important;
    height: 86px;
    top: -3.4rem;
    right: -5rem;
    z-index: 1;
  }
  .gateway-footer .footer-btn > a > button {
    background: #2e2b69;
    border-radius: 52px;
    width: 190px;
    height: 57px;
    border: none;
  }
  .gateway-footer .footer-btn > a > button > span {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #ffffff;
  }
}
