.footer {
  position: relative;
  overflow: hidden;
  height: 850px;
  z-index: 2;
  background: #13122b;
}

.footer::before {
  position: absolute;
  width: 100%;
  height: 8rem;
  left: 0;
  right: 0;
  bottom: 0;
  top: -7rem;
  content: "";
  background: #f5f5f5;
  transform-origin: top right;
  transform: skewY(-4deg);
  z-index: 3;
}
.ellipse-bg1 {
  background: url("../../images/Ellipse-f-1.png");
  width: 100%;
  height: 440px;
  background-repeat: no-repeat;
  position: relative;
  right: -10rem;
  z-index: 2;
  top: -4rem;
  /* */
}
.ellipse-bg2 {
  background: url("../../images/Ellipse-f-2.png");
  width: 1900px;
  height: 748.33px;
  background-repeat: no-repeat;
  position: absolute;
  top: -6rem;
  left: -3rem;
  /* border: 1px saddlebrown solid; */
  z-index: 1;
}
.ellipse-bg3 {
  background: url("../../images/Ellipse-f-3.png");
  width: 2100px;
  height: 947px;
  background-repeat: no-repeat;
  position: absolute;
  background-color: #13122b;
  /* border: 1px saddlebrown solid; */
  top: -10rem;
  left: -4rem;
}

.footer-heading {
  position: relative;
  z-index: 3;
  top: 19rem;
  right: 0rem;
  /* border: 1px saddlebrown solid; */
  display: flex;
  align-items: center;
  gap: 10rem;
}

.footer-logo {
  /* border: 1px rgb(20, 211, 179) solid; */
  display: flex;
  align-items: center;
  gap: 2rem;
}

.footer-logo > span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;

  /* identical to box height */

  color: #ffffff;
}
.footer-logo > div {
  width: 50px;
  height: 50px;
  background: #4e48b1;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo > div > img {
  width: 20px;
  height: 26px;
  margin-top: 4px;
  margin-left: 1px;
}

.footer-heading-item {
  display: flex;
  /* border: #4E48B1 1px solid; */
  gap: 10rem;
  align-items: center;
  justify-content: center;
}
.footer-heading-items {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;

  /* secondary/grey scale/4 */
  color: #7c7e8b;
}

.footer-heading-items > a {
  text-decoration: none;
  color: #7c7e8b;
}
.footer-body {
  position: relative;
  z-index: 3;
  top: 21rem;
  right: -22rem;
  /* border: 1px saddlebrown solid; */
  display: flex;
  /* align-items: center; */
  gap: 10rem;
}

.footer-body .contact-info{
  width: 22rem;
  position: absolute;
  left: -24rem;
}
.footer-body ul {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: left;
  /* border: red 1px solid; */
  gap: 1rem;
}

.footer-body ul > li {
  list-style: none;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}

.copy {
  position: relative;
  z-index: 2;
  left: -16rem;
  top: 17rem;
}

.social-icons {
  gap: 1.5rem;
  z-index: 2;
  padding-left: 2rem;
}
.social-icons > .upper-icon {
  display: flex;
  gap: 1.5rem;
  padding-bottom: 1rem;
}
.social-icons > .lower-icon {
  display: flex;
  gap: 1.5rem;
}
.social-icons > .upper-icon > div {
  border: 1px solid #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icons > .lower-icon > div {
  border: 1px solid #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icons > div img {
  width: 24px;
}

.lower-icon img {
  width: 22.67px;
  height: 22px;
}

.ellipse-bg3 .s-d-1 {
  position: relative;
  z-index: 1;
  top: 34rem;
  right: -8rem;
}
.ellipse-bg3 .s-d-2 {
  position: relative;
  z-index: 3;
  top: 27rem;
  right: 65rem;
}
.s-d-3 {
  position: absolute;
  z-index: 3;
  top: 1rem;
  right: 0%;
}

/* footer mobile */

.s-d-l-m1 {
  position: absolute;
  top: 47rem;
  left: 0rem;
  z-index: 3;
}
.s-d-l-m2 {
  position: absolute;
  top: 25rem;
  right: 0rem;
  z-index: 2;
}
.ellipse-bg4 {
  background: url("../../images/Ellipse-f-1.png");
  width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  position: relative;
  right: 10rem;
  z-index: 2;
  top: 10rem;
  /* */
  -moz-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
}
.ellipse-bg5 {
  background: url("../../images/Ellipse-f-2.png");
  width: 1900px;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 4rem;
  right: -3rem;
  /* border: 1px saddlebrown solid; */
  z-index: 1;
  -moz-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.ellipse-bg4 .footer-heading-m {
  -moz-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
  position: relative;
  z-index: 3;
  top: 32rem;
  left: -10rem;
  /* border: 1px saddlebrown solid; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  text-align: left;
  padding-left: 2rem;
}
.footer-heading-item1 {
  -moz-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  left: -10rem;
  top: 2rem;
  /* */
  padding: 0 1rem;
}
li {
  list-style: none;
}
li a {
  text-decoration: none;
  color: #ffffff;
}
.footer-heading-item1 .footer-heading-items {
  padding: 1rem 0;

  width: 100%;
}

.accordion {
  list-style: none;
}

.accordion_item {
  border-top: 0.5px solid #323549;
  width: 100%;
}

.button {
  background-color: transparent;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border: none;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  /* secondary/grey scale/6 */
  color: #adaeb6;
}

.control {
  font-size: 20px;
}

.answer {
  background-color: transparent;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}

.social-icons-m {
  display: flex !important;
}
.answer li {
  cursor: pointer;
  list-style: none;
}

/* activate toggle */
.accordion_item.active .button {
  background-color: transparent;
}
.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

@media screen and (max-width: 1250px) {
  .footer-heading {
    position: relative;
    z-index: 3;
    top: 19rem;
    right: 6rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    align-items: center;
    gap: 8rem;
  }

  .footer-body {
    position: relative;
    z-index: 3;
    top: 21rem;
    right: -15rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    /* align-items: center; */
    gap: 9rem;
  }

  .footer-body .contact-info{
    width: 22rem;
    position: absolute;
    left: -23rem;
  }
  .copy {
    position: relative;
    z-index: 2;
    left: -20rem;
    top: 17rem;
  }
  .ellipse-bg3 .s-d-1 {
    position: relative;
    z-index: 1;
    top: 34rem;
    right: 3rem;
  }
  .ellipse-bg3 .s-d-2 {
    position: relative;
    z-index: 3;
    top: 27rem;
  }
}

@media screen and (max-width: 1120px) {
  .footer-heading {
    position: relative;
    z-index: 3;
    top: 19rem;
    right: 8rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    align-items: center;
    gap: 8rem;
  }

  .footer-body {
    position: relative;
    z-index: 3;
    top: 21rem;
    right: -13rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    /* align-items: center; */
    gap: 9rem;
  }

  .footer-body .contact-info{
    width: 22rem;
    position: absolute;
    left: -23rem;
  }

}


@media screen and (max-width: 1070px) {
  .footer-heading {
    position: relative;
    z-index: 3;
    top: 19rem;
    right: 5rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    align-items: center;
    gap: 5rem;
  }
  .footer-heading .footer-heading-item {
    display: flex;
    /* border: #4E48B1 1px solid; */
    gap: 7rem;
    align-items: center;
    justify-content: center;
  }

  .footer-body {
    position: relative;
    z-index: 3;
    top: 21rem;
    right: -13rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    /* align-items: center; */
    gap: 6rem;
  }

  .footer-body .contact-info{
    width: 15rem;
    position: absolute;
    left: -19rem;
  }

  .copy {
    position: relative;
    z-index: 2;
    left: -26rem;
    top: 17rem;
  }
  .ellipse-bg3 .s-d-1 {
    position: relative;
    z-index: 1;
    top: 34rem;
    right: 7rem;
  }
  .ellipse-bg3 .s-d-2 {
    position: relative;
    z-index: 3;
    top: 27rem;
  }
}

@media screen and (max-width: 1000px) {
  .footer-heading {
    position: relative;
    z-index: 3;
    top: 19rem;
    right: 7rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    align-items: center;
    gap: 5rem;
  }
  .footer-heading .footer-heading-item {
    display: flex;
    /* border: #4E48B1 1px solid; */
    gap: 7rem;
    align-items: center;
    justify-content: center;
  }

  .footer-body {
    position: relative;
    z-index: 3;
    top: 21rem;
    right: -10rem;
    /* border: 1px saddlebrown solid; */
    display: flex;
    /* align-items: center; */
    gap: 6rem;
  }

  .footer-body .contact-info{
    width: 15rem;
    position: absolute;
    left: -19rem;
  }
}




@media screen and (max-width: 950px) {
  .footer-mobile .contact-info-m{
    display: flex;
    color: white;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: center;
    width: 25rem;
    gap: 0.5rem;
    /* background-color: red; */
    /* margin: 2rem 0; */
  }
  .copy {
    position: absolute;
    z-index: 2;
    left: 9%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }

  .social-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
  }
  .social-icons div {
    border: 1px solid #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-logo span {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;

    /* identical to box height */

    color: #ffffff;
  }
  .footer-heading .footer-logo > div {
    width: 40px;
    height: 40px;
    background: #4e48b1;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: 20%;
    top: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }
  .app-store-link {
    width: 91.65px;
    height: 29.51px;
  }
  .app-store-link img {
    width: 100%;
    height: 100%;
  }

  .play-store-link {
    width: 104.11px;
    height: 30.53px;
  }
  .play-store-link img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 730px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: 15%;
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: 3%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }

  
}
@media screen and (max-width: 660px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: 7%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }
  .copy {
    position: absolute;
    z-index: 2;
    left: -6%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }

  
}
@media screen and (max-width: 580px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: 2%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -16%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }

 
}
@media screen and (max-width: 500px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -7%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -26%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
  
  .answer {
    background-color: transparent;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;

    color: #ffffff;
  }

  .contact-info-m li{
    list-style: none;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  
    color: #ffffff;
  }
}
@media screen and (max-width: 460px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -12%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }
  .copy {
    position: absolute;
    z-index: 2;
    left: -28%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
 
  .answer {
    background-color: transparent;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;

    color: #ffffff;
  }

 
}
@media screen and (max-width: 425px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -15%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }
  .copy {
    position: absolute;
    z-index: 2;
    left: -33%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
  .answer {
    background-color: transparent;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;

    color: #ffffff;
  }
  .footer-mobile .contact-info-m{
    display: flex;
    color: white;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: center;
    width: 20rem;
    /* background-color: red; */
    /* margin: 2rem 0; */
  }

  .contact-info-m li{
    list-style: none;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  
    color: #ffffff;
  }
}
@media screen and (max-width: 410px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -15%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -35%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
  .s-d-l-m1 {
    position: absolute;
    top: 47rem;
    left: -2rem;
    z-index: 3;
  }
 
  li {
    list-style: none;
  }
  .footer-heading-item1 .footer-heading-items {
    padding: 1rem 0;

    width: 100%;
  }

  .accordion {
    list-style: none;
  }

  .accordion_item {
    border-top: 0.5px solid #323549;
    width: 100%;
  }

  .button {
    background-color: transparent;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 8px;
    cursor: pointer;
    border: none;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    /* secondary/grey scale/6 */
    color: #adaeb6;
  }

  .control {
    font-size: 20px;
  }

  .answer {
    background-color: transparent;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;

    color: #ffffff;
  }

  .social-icons-m {
    display: flex !important;
  }
  .answer li {
    cursor: pointer;
    list-style: none;
  }

  /* activate toggle */
  .accordion_item.active .button {
    background-color: transparent;
  }
  .answer_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
  }
}

@media screen and (max-width: 400px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -18%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -37%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
}

@media screen and (max-width: 390px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -18%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -38.5%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
}
@media screen and (max-width: 385px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -19%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -39.5%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
}
@media screen and (max-width: 380px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -19%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -40.5%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
}
@media screen and (max-width: 377px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -22%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -29%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
}
@media screen and (max-width: 360px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -25%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -33%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }

  .footer-mobile .contact-info-m{
    display: flex;
    color: white;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: center;
    width: 18rem;
    /* background-color: red; */
    /* margin: 2rem 0; */
  }

  .contact-info-m li{
    list-style: none;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  
    color: #ffffff;
  }
}

@media screen and (max-width: 340px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -32%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -39%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
  .social-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
  }
  .social-icons div {
    border: 1px solid #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-icons > div img {
    width: 16px;
  }
  .answer {
    background-color: transparent;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;

    color: #ffffff;
  }
}
@media screen and (max-width: 332px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -32%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }
  .copy {
    position: absolute;
    z-index: 2;
    left: -41%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
}
@media screen and (max-width: 325px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -33%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }
  .copy {
    position: absolute;
    z-index: 2;
    left: -43%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
}
@media screen and (max-width: 318px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -34%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }
  .copy {
    position: absolute;
    z-index: 2;
    left: -45%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }

  .footer-mobile .contact-info-m{
    display: flex;
    color: white;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: center;
    width: 16rem;
    /* background-color: red; */
    /* margin: 2rem 0; */
  }

  .contact-info-m li{
    list-style: none;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  
    color: #ffffff;
  }
}

@media screen and (max-width: 310px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -36%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -47%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
  .answer {
    background-color: transparent;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;

    color: #ffffff;
  }
}


@media screen and (max-width: 305px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -37%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -50%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
}

@media screen and (max-width: 297px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -40%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -46%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
  .copy{
    font-size: 9px;
  }

  .footer-mobile .contact-info-m{
    display: flex;
    color: white;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: center;
    width: 15rem;
    /* background-color: red; */
    /* margin: 2rem 0; */
  }

  .contact-info-m li{
    list-style: none;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  
    color: #ffffff;
  }
}

@media screen and (max-width: 290px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -45%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -50%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
  .copy{
    font-size: 9px;
  }
}

@media screen and (max-width: 279px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -48%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -50%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 10px;
  }
  .copy{
    font-size: 8px;
  }
}
@media screen and (max-width: 270px) {
  .store-link {
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    position: absolute;
    left: -53%;
   
    display: flex;
    align-items: center;
    gap: 9px;
    width: 233px;
    height: 53.59px;
    background: #0e0c21;
    box-shadow: 0px 3.10667px 24.0767px rgba(57, 57, 57, 0.11);
    border-radius: 10.0967px;
  }

  .copy {
    position: absolute;
    z-index: 2;
    left: -53%;
    top: -1rem;
    -moz-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -ms-transform: scale(-1, -1);
    transform: scale(-1, -1);
    font-size: 8px;
  }
  .answer {
    background-color: transparent;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 15px;

    color: #ffffff;
  }
}
