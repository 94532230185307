.security{
    display: flex;
    flex-direction: column;
    padding:7rem 0 7rem 0;
    /* padding-top: 0rem; */
    gap: 7rem;
    top: 4rem;
    background: #F5F5F5;
    top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index: 1;
}

.security-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.security-heading .head{
    display: flex;
    gap: 2rem;
}

.security-heading>h3{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 104.12%;

/* identical to box height, or 15px */
letter-spacing: 0.465em;
text-transform: uppercase;

color: #80D1FF;
padding-right: 2.5rem;


}

.head h1{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 104px;
line-height: 131px;

/* identical to box height */
letter-spacing: -0.03em;

/* primary/3 */
color: #2E2B69;


}
.head-r{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.head span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 53px;
letter-spacing: 0.01em;

/* primary/3 */
color: #2E2B69;


}

.security-body{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
    padding: 0 10rem;

}

.sec-body-l{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    flex: 1.1;
}

.sec-body-l-img{
    display: flex;
    align-items: center;
}
.sec-body-l-txt{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.sec-body-l-txt .txt-lock{
    display: flex;
    align-items: center;
}
.sec-body-l-txt span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 53px;
letter-spacing: -0.03em;

/* secondary/grey scale/2 */
color: #4B4D5F;


}
.sec-body-l-txt .txt-lock img{
    width: 70px;
height: 70px;
}

.sec-body-r{
    flex: 1;

}

.sec-body-r p{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 32px;
/* or 200% */
letter-spacing: 0.01em;

/* secondary/grey scale/5 */
color: #9496A0;


}

.sec-body-r button{
    background: #2E2B69;
border-radius: 51px;
width: 169px;
height: 60px;
border: none;
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 32px;

/* identical to box height, or 200% */
letter-spacing: 0.01em;

color: #FFFFFF;
}

.sec-body-r button .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 110%;
    left: 60%;
    z-index: 3;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
}



.sec-body-l-txt> div{
    text-align: left;
}

.swiss{
    width: 170px;
height: 71px;
}

.icici{
    width: 189px;
height: 95px;
}
.payment-card-img{
    width: 179.96px;
    height: 179.96px;
    position: absolute;
    top: 17rem;
    left: 3rem;
}
.payment-card-img>img{
    width: 100%;
    height: 100%;
}

.credit-machine-sec{
    width: 90px;
    height: 90px;
    position: absolute;
    right: 20rem;
    top: 8rem;
}

.credit-machine-sec>img{
    width: 100%;
    height: 100%;
}

.ellipse-sec-1{
    position: absolute;
    width: 70px;
height: 70px;
top: 25rem;
left: 30rem;
}
.ellipse-sec-1>img{
    width: 100%;
    height: 100%;
}
.ellipse-sec-2{
    position: absolute;
    width: 75px;
height: 75px;
top: 42rem;
right: 7rem;
}

.ellipse-sec-2>img{
    width: 100%;
    height: 100%;
}
.ellipse-sec-3{
    position: absolute;
    width: 75px;
height: 75px;
top: 25rem;
right: 27rem;
}

.ellipse-sec-3>img{
    width: 100%;
    height: 100%;
}

.gold-coin-sec{
    position: absolute;
    width: 60px;
    height: 60px;
    top:45rem;
    left: 38rem;
}

.gold-coin-sec>img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width:1250px){
    .ellipse-sec-1{
        position: absolute;
        width: 70px;
    height: 70px;
    top: 25rem;
    left: 25rem;
    }
    .ellipse-sec-2{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 42rem;
    right: 4rem;
    }
    .ellipse-sec-3{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 25rem;
    right: 23rem;
    }
.gold-coin-sec{
    position: absolute;
    width: 60px;
    height: 60px;
    top:45rem;
    left: 35rem;
}
.sec-body-r button .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 115%;
    left: 57%;
    z-index: 3;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
}


}
@media screen and (max-width:1160px){
    .payment-card-img{
        width: 179.96px;
        height: 179.96px;
        position: absolute;
        top: 17rem;
        left: 3rem;
    }
.credit-machine-sec{
    width: 90px;
    height: 90px;
    position: absolute;
    right: 15rem;
    top: 8rem;
}
    .ellipse-sec-2{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 44rem;
    right: 4rem;
    }
    .ellipse-sec-1{
        position: absolute;
        width: 70px;
    height: 70px;
    top: 25rem;
    left: 20rem;
    }
   
    .ellipse-sec-3{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 25rem;
    right: 20rem;
    }
.gold-coin-sec{
    position: absolute;
    width: 60px;
    height: 60px;
    top:45rem;
    left: 30rem;
}
.sec-body-r button .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 115%;
    left: 55%;
    z-index: 3;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
}

}
@media screen and (max-width:1050px){
    .security-body{
        display: flex;
        gap: 3rem;
        padding: 0 5rem;
    }
    .ellipse-sec-2{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 44rem;
    right: 1rem;
    }
    .gold-coin-sec{
        position: absolute;
        width: 60px;
        height: 60px;
        top:45rem;
        left: 22rem;
    }
    .credit-machine-sec{
        width: 90px;
        height: 90px;
        position: absolute;
        right: 10rem;
        top: 8rem;
    }
    .ellipse-sec-1{
        position: absolute;
        width: 70px;
    height: 70px;
    top: 25rem;
    left: 17rem;
    }
   
    .ellipse-sec-3{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 25rem;
    right: 17rem;
    }
    .payment-card-img{
        width: 179.96px;
        height: 179.96px;
        position: absolute;
        top: 17rem;
        left: -1rem;
    }
    .sec-body-r button .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 115%;
    left: 56%;
    z-index: 3;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
}


}

@media screen and (max-width:868px){
    .sec-body-r button .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 120%;
        left: 56%;
        z-index: 3;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
    }
}
@media screen and (max-width:850px){
    .sec-body-r button .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 115%;
        left: 56%;
        z-index: 3;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
    }
    
    .security-body{
        display: flex;
        gap: 1rem;
        padding: 0 1rem;
    }
    .gold-coin-sec{
        position: absolute;
        width: 60px;
        height: 60px;
        top:45rem;
        left: 21rem;
    }
    .ellipse-sec-1{
        position: absolute;
        width: 70px;
    height: 70px;
    top: 25rem;
    left: 10rem;
    }
    .ellipse-sec-2{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 44rem;
    right: -1rem;
    }
   
    .ellipse-sec-3{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 25rem;
    right: 10rem;
    }
    .payment-card-img{
        width: 179.96px;
        height: 179.96px;
        position: absolute;
        top: 17rem;
        left: -3rem;
    }
}
@media screen and (max-width:750px){
    .security-body{
        display: flex;
        gap: 3rem;
        padding: 0 3rem;
        flex-direction: column;
        
    }
    .sec-body-r p{
        font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    /* or 200% */
    letter-spacing: 0.01em;
    text-align: center;
    /* secondary/grey scale/5 */
    color: #9496A0;
    
    
    }

    .gold-coin-sec{
        position: absolute;
        width: 60px;
        height: 60px;
        top:45rem;
        left: 21rem;
        display: none;
    }
    .ellipse-sec-1{
        position: absolute;
        width: 70px;
    height: 70px;
    top: 25rem;
    left: 10rem;
    display: none;
    }
    .ellipse-sec-2{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 44rem;
    right: -1rem;
    display: none;
    }
   
    .ellipse-sec-3{
        position: absolute;
        width: 75px;
    height: 75px;
    top: 25rem;
    right: 10rem;
    display: none;
    }
    .payment-card-img{
        width: 179.96px;
        height: 179.96px;
        position: absolute;
        top: 17rem;
        left: -3rem;
        display: none;
    }
    .credit-machine-sec{
        display: none;
    }
 
}

@media screen and (max-width:700px) {
    
.head h1{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 84px;
line-height: 111px;

/* identical to box height */
letter-spacing: -0.03em;

/* primary/3 */
color: #2E2B69;


}
.head-r{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.head span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 43px;
letter-spacing: 0.01em;

/* primary/3 */
color: #2E2B69;


}
}
@media screen and (max-width:500px) {
    .line-secq{
        display: none;
    }
    
.head h1{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 54px;
line-height: 90px;

/* identical to box height */
letter-spacing: -0.03em;

/* primary/3 */
color: #2E2B69;


}
.head-r{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.head span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 43px;
letter-spacing: 0.01em;

/* primary/3 */
color: #2E2B69;


}
}

@media screen and (max-width:400px){
    .security-body{
        display: flex;
        gap: 3rem;
        padding: 0 1.5rem;
        flex-direction: column;
        
    }
    .sec-body-l-txt span{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size:30.3333px;
line-height: 29px;
letter-spacing: -0.03em;

/* secondary/grey scale/2 */
color: #4B4D5F;


    }

    .sec-body-r p{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 32px;

/* or 200% */
text-align: center;
letter-spacing: 0.03em;

/* secondary/grey scale/5 */
color: #9496A0;
    }
    .sec-body-l-img >img{
        width: 105px;
height: 52.78px;
    }
}


@media screen and (max-width:320px) {
    
    .head h1{
        font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 64px;
    
    /* identical to box height */
    letter-spacing: -0.03em;
    
    /* primary/3 */
    color: #2E2B69;
    
    
    }
    .head-r{
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    
    .head span{
        font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0.01em;
    
    /* primary/3 */
    color: #2E2B69;
    
    
    }
    }
    