.simple-cart-sec{
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.black{
    color: #000000;
}
.simple-cart{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    /* border: 1px solid black; */
    margin:0 5rem;
    padding: 2rem;
    padding-top: 0;
    gap: 42px;
}
.simple-cart-header{
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    gap: 71px;
}
.simple-cart-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.simple-cart-logo-img{
    width: 46px;
    height: 46px;
}
.simple-cart-logo-img>img{
    width: 100%;
    height: 100%;
}

.simple-cart-logo-text>span{
    font-family: 'Helvetica Neue';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 32px;

/* identical to box height */

color: #000000;


}

.simple-cart-text{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.simple-cart-header-text1>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;

color: #000000;


}
.simple-cart-header-text1>span>.strong-text{
    font-size: 18px;
    font-weight: 900;
}
.simple-cart-header-text2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.simple-cart-header-text2>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
text-align: center;

/* primary/4 */
color: #4E48B1;


}

.simple-cart-body>form{
    display: flex;
    flex-direction: column;
    gap: 57px;
}

.simple-cart-body>form>.input-field{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px; 
}
.input-field>label{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

/* Greyscale/Dark/1 */
color: #323549;


}

.input-field>input{
    width: 388px;
height: 52px;
border: 1px solid #E8E8EB;
border-radius: 8px;
}

.input-field>input::placeholder{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;

/* grey scale light /2 */
color: #BCBDC3;


}
.button-cart{
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-cart>button{
    width: 188px;
height: 59px;
background: #2E2B69;
border-radius: 49px;
border: none;
color: #FFFFFF;
}

.button-cart>button:hover{
    background-color: #4E48B1;
}

.simple-cart-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 13px;
}
.logo-footer{
    width: 100px;
height: 31px;
}

.logo-footer>img{
    width: 100%;
    height: 100%;
}

.text-footer-simple-cart{
    display: flex;
    flex-direction: column;
}
.text-footer-simple-cart>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;

/* or 150% */

/* secondary/grey scale/4 */
color: #7C7E8B;


}

.dwonload-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
}
.dwonload-btn>.dwonload-btn-text{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;

color: #000000;
}

.dwonload-btn>.dwonload-btn-img{
    width: 95px;
height: 72px;
}

.dwonload-btn>.dwonload-btn-img>img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:450px){
    .input-field>input{
    width: 320px;
    height: 52px;
    border: 1px solid #E8E8EB;
    border-radius: 8px;
    }
    .simple-cart{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        /* border: 1px solid black; */
        margin:0;
        width: 100%;
        padding: 0rem;
        padding-top: 0;
        gap: 50px;
    }
    .simple-cart-body>form{
        display: flex;
        flex-direction: column;
        gap: 27px;
    }
    
    
}
@media screen and (max-width:330px){
    .input-field>input{
        width: 265px;
    height: 52px;
    border: 1px solid #E8E8EB;
    border-radius: 8px;
    }
    
}
@media screen and (max-width:300px){
    .input-field>input{
        width: 250px;
    height: 52px;
    border: 1px solid #E8E8EB;
    border-radius: 8px;
    }
    
}