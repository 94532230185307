.card-stack{
    background: #13122B;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-top: 150px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;
    z-index: 2;
}
.card-stack::before{
    position: absolute;
    width: 100%;
    height: 8rem;
    left: 0;
    right: 0;
    bottom: 0;
    top: -7rem;
    content: '';
    background: #F5F5F5;
    transform-origin: top right;
    transform: skewY(-4deg);
    z-index: 2;
}
.card-stack .card-stack-heading{
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* margin-left: 5rem; */
}

.card-stack-heading .heading-small>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 104.12%;

/* identical to box height, or 15px */
letter-spacing: 0.465em;
text-transform: uppercase;

color: #80D1FF;


}
.card-stack-heading .heading-big>span{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 81px;
    
    /* identical to box height */
    letter-spacing: 0.01em;
    
    color: #FFFFFF;
    
    


}

.card-stack-heading .heading-big .special-card-text{
    color: #FFC852;
}

.heading-big>img{
    position: absolute;
    width: 179.96px;
    height: 179.96px;
    left: 4rem;
    top: 12rem;
    /* bottom: 3rem; */
}

.heading-small>img{
    position: absolute;
    width: 90px;
    height: 90px;
    right: 17rem;
    top: 7rem;
}
.card-stack-body{
    display: flex;
    padding: 0 6rem 0 12rem;
    justify-content: space-between;
}

.card-body-r{
    transform: scaleX(-1);
    /* transform: scaleX(1); */
}

.card-stack-body .card-body-r>img{
    width: 300.44px;
    height: 410.69px;
   
}
.card-stack-body .card-body-r{
    /* border: rgb(146, 204, 12) 1px solid; */
    display: flex;
    /* padding-top: 3rem; */
    justify-content: flex-end;
}
.card-stack-body .card-body-l{
    display: flex;
    flex-direction: column;
    /* border: rgb(173, 144, 144) 1px solid; */
    width: 480px;
    gap: 2rem;
    padding-top: 8rem;
}
.card-stack-body .card-body-l .heading-l{
    text-align: left;

}
.card-stack-body .card-body-l .text-l{
    text-align: left;
}
.card-stack-body .card-body-l .heading-l>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 42px;
line-height: 58px;

/* identical to box height, or 138% */

/* grey scale light/6 */
color: #f8f8f7;



}

.card-stack-body .card-body-l .text-l>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 32px;

/* or 200% */
letter-spacing: 0.01em;
text-align: left;
color: #9496A0;
}


@media screen and (max-width:1200px){
    .heading-big>img{
        position: absolute;
        width: 179.96px;
        height: 179.96px;
        left: 0rem;
        top: 12rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 90px;
        height: 90px;
        right: 9rem;
        top: 7rem;
    }
}

@media screen and (max-width:1024px){
    .heading-big>img{
        position: absolute;
        width: 179.96px;
        height: 179.96px;
        left: 0rem;
        top: 14rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 90px;
        height: 90px;
        right: 5rem;
        top: 7rem;
    }
    .card-stack-body{
        display: flex;
        padding: 0 3rem 0 3rem;
        justify-content: space-between;
    }
}
@media screen and (max-width:850px){
    .card-stack-body{
        display: flex;
        padding: 0 2rem 0 2rem;
        justify-content: space-between;
    }
    .heading-big>img{
        position: absolute;
        width: 179.96px;
        height: 179.96px;
        left: -2rem;
        top: 14rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 90px;
        height: 90px;
        right: 2rem;
        top: 7rem;
    }
}

@media screen and (max-width:768px){
    .heading-big>img{
        position: absolute;
        width: 179.96px;
        height: 179.96px;
        left: -2rem;
        top: 30rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 90px;
        height: 90px;
        right: 2rem;
        top: 17rem;
    }
    
    .card-stack-heading .heading-big>span{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.01em;
        
        color: #FFFFFF;
    }
    .card-stack-heading .heading-small>span{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 12px;
line-height: 104.12%;

/* or 12px */
letter-spacing: 0.465em;
text-transform: uppercase;

color: #80D1FF;


    }
    .card-stack-body{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 3rem;
        justify-content: center;
    }
    .card-stack-body .card-body-r{
        /* border: rgb(146, 204, 12) 1px solid; */
        display: flex;
        /* padding-top: 3rem; */
        justify-content: center;
    }
    .card-stack-body .card-body-l{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: rgb(173, 144, 144) 1px solid; */
        width: 100%;
        gap: 2rem;
        padding: 0 2rem;
        padding-top: 3rem;
    }
    .card-stack-body .card-body-l .heading-l{
        text-align: center;
    
    }
    .card-stack-body .card-body-l .text-l{
        text-align: center;
    }
    .card-stack-body .card-body-l .heading-l>span{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 44px;
        
        /* or 155% */
        text-align: center;
        
        color: #FFFFFF;
    }
    
    .card-stack-body .card-body-l .text-l>span{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        
        /* or 200% */
        text-align: center;
        letter-spacing: 0.01em;
        
        /* secondary/grey scale/5 */
        color: #9496A0;        
}
}

@media screen and (max-width:550px) {
    .card-stack-body .card-body-r>img{
        width: 176.68px !important;
        height: 240.83px !important;
    }
    .heading-big>img{
        position: absolute;
        width: 77px;
        height: 77px;
        left: 3rem;
        top: 30rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 55px;
        height: 55px;
        right: 4rem;
        top: 17rem;
    }
}
@media screen and (max-width:450px){
    .card-stack-body{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 1rem;
        justify-content: center;
    }
    .heading-big>img{
        position: absolute;
        width: 77px;
        height: 77px;
        left: 1rem;
        top: 30rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 55px;
        height: 55px;
        right: 4rem;
        top: 19rem;
    }
}
@media screen and (max-width:400px){
    .card-stack-body .card-body-l .heading-l>span{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 44px;
        
        /* or 155% */
        text-align: center;
        /* border: red solid 2px; */
        color: #FFFFFF;
    }
    .card-stack-body .card-body-l .heading-l{
        display: flex;
        flex-direction: column;
    }
    .card-stack-body .card-body-l{
        padding: 0;
    }
    .heading-big>img{
        position: absolute;
        width: 77px;
        height: 77px;
        left: 0rem;
        top: 30rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 55px;
        height: 55px;
        right: 2rem;
        top: 20rem;
    }
}

@media screen and (max-width:290px){
    .heading-big>img{
        position: absolute;
        width: 77px;
        height: 77px;
        left: -1rem;
        top: 30rem;
        /* bottom: 3rem; */
    }
    
    .heading-small>img{
        position: absolute;
        width: 55px;
        height: 55px;
        right: 1rem;
        top: 20rem;
    }
}