@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
.main-page{
    background-color: #13122B;
    width: 100%;
    height: 900px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}
/* .main-page::after{
    position: absolute;
    width: 100%;
    height: 32%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50rem;
    content: '';
    background: #13122B;
    transform-origin: top right;
    transform: skewY(-3deg);
    z-index: 1;
} */

a{
  position: relative;
  z-index: 1;
}
.nav-bar {
  background: #0e0c21;
  box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.06);
  border-radius: 70px;
  width: 80%;
  height: 75px;
  position: relative;
  left: 9%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 2rem;

}

.logo-items {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  z-index: 3;
}
.logo-items .logo-img {
  background: #4e48b1;
  border-radius: 40px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-text{
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  
  /* identical to box height */

  color: #ffffff;
}
.logo-text-2{
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  
  /* identical to box height */

  color: #ffffff;
}
.menu-items div {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.menu-items div li {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  list-style: none;

  /* identical to box height */

  /* grey scale light/4 */
  color: #d9dadd;
}
.list a{
  text-decoration: none;
}

.list  li .play-store{
  display: flex;
  gap: 1rem;
  position: absolute;
  top: 6rem;
  left: 74%;
  z-index: 1;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.menu-items div li a{
  text-decoration: none;
  color: #d9dadd;
  
}
.menu-icon{
  display: none;
}
.btn-app {
  background: #2e2b69;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  width: 152px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 1rem; */
  cursor: pointer;
}
.bg1 {
  position: absolute;
  background-image: url("../../images/bg1.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 120px;
  height: 2059.16px;
}

.bg2 {
  position: absolute;
  background: url("../../images/bg2.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 268px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 1600.33px;
}

.bg2 > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

}
.bg2 > div > span {
  font-family: "DM Serif Display";
  font-style: italic;
  font-weight: 400;
  font-size: 70.011px;
  line-height: 117.7%;

  /* identical to box height, or 124px */
  text-align: center;

  color: #ffffff;
}

.special-text {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 40.1459px !important;
  line-height: 117.7% !important;

  /* identical to box height, or 83px */
  text-align: center;

  color: #ffffff;

}
.trolly-img {
  background: #ffffff;
  /* grey scale light/4 */
  border: 4.64928px solid #d9dadd;
  border-radius: 43.0058px;
  width: 72px;
  height: 53px;
  display: flex;
  justify-content: center;
}
.trolly-img > img {
  width: 113.72px;
height: 84.51px;
position: relative;
top: -1.2rem;
left: -0.5rem;
}

.box-1 {
  position: relative;
  left: 0rem;
  top: -2.9rem;

}
.box-2 {
  position: relative;
  left: -1rem;
  top: -3.5rem;
  
}
.box-3 {
  position: relative;
  left: -1rem;
  top: -4.5rem;
  z-index: 1;
}
.box-3> img{
  width: 72px;
}

.bg3 {
  position: absolute;
  background: url("../../images/bg3.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 420px;
  height: 1231.24px;
}

.bg3 > div {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  
}

.gold-text{
    position: relative;
    top: 1.7rem;
    left: -1rem;

}
.gold-text > h1 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 56.0116px;
  line-height: 117.7%;

  /* or 101px */
  text-align: center;
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffde97;
}
.gold-text>p{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
text-align: center;
letter-spacing: 0.02em;

/* grey scale light/4 */
color: #D9DADD;
}
/* .bg2{
    position: absolute;
    width: 1648.33px;
    height: 1648.33px;

    
    background: #111027;
    transform: rotate(-6deg); 
} */

.play-store{
    position: relative;
    top: 0rem;
    left: -1rem;
    display: flex;
    align-items: center;
    gap: 6px;
}

.play-store-icon{
  background: #0E0C21;
  box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
  border-radius: 13px;
  width: 152px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-store-icon img{
width: 136px;
height: 40px;
}

.ios-store{
  background: #0E0C21;
box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
border-radius: 13px;
width: 152px;
height: 69px;
display: flex;
align-items: center;
justify-content: center;
}
.ios-store img{
  width: 136px;
height: 43px;
}

.coin-main{
  position: relative;
  width: 75.13px;
height: 75.13px;
left: -20rem;
top: -31rem;
-webkit-animation:spin 3s linear infinite;
-moz-animation:spin 3s linear infinite;
animation:spin 3s linear infinite;
}

.coin-main-r{
  position: relative;
  width: 65px;
height: 65px;
right: -31rem;
top: -34rem;
-webkit-animation:spin 3s linear infinite;
-moz-animation:spin 3s linear infinite;
animation:spin 3s linear infinite;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}
.credit-m{
  position: relative;
  width: 99.67px;
  height: 99.67px;
  left: -20rem;
  top: -24rem;
}

.paper-b{
  position: relative;
  width: 99.67px;
  height: 99.67px;
  right: -10rem;
  top: -26rem;
}

.gold-coin{
  width: 58px;
height: 58px;
position: absolute;
left: 16rem;
top: 6rem;
-webkit-animation:spin 3s linear infinite;
-moz-animation:spin 3s linear infinite;
animation:spin 3s linear infinite;
}

.blue-coin{
  width: 59px;
height: 59px;
position: absolute;
right: 15rem;
top: 4rem;
-webkit-animation:spin 3s linear infinite;
-moz-animation:spin 3s linear infinite;
animation:spin 3s linear infinite;
}

.side-l{
  position: absolute;
  top: -10rem;
  /* right: 7rem; */
  right: 0rem;
}
.side-r{
  position: absolute;
  top: 15rem;
  /* left: -6rem; */
  left: 0rem;
}
.side-b{
  position: absolute;
  top: 19rem;
  right: 22rem;
}






@media screen and (max-width:1200px) {
  .coin-main-r{
    position: relative;
    width: 65px;
  height: 65px;
  right: -22rem;
  top: -37rem;
  }
  .paper-b{
    position: relative;
    width: 99.67px;
    height: 99.67px;
    right: -7rem;
    top: -26rem;
  }
  .blue-coin{
    width: 59px;
  height: 59px;
  position: absolute;
  right: 18rem;
  top: 5rem;
  }
  /* .coin-main{
    position: relative;
    width: 75.13px;
  height: 75.13px;
  left: -22rem;
  top: -26rem;
  }
  

  
  .credit-m{
    position: relative;
    width: 99.67px;
    height: 99.67px;
    left: -26rem;
    top: -18rem;
  }
  
  .paper-b{
    position: relative;
    width: 99.67px;
    height: 99.67px;
    right: -3rem;
    top: -21rem;
  }
  
  .gold-coin{
    width: 58px;
  height: 58px;
  position: absolute;
  left: 13rem;
  top: 6rem;
  }
  
  .blue-coin{
    width: 59px;
  height: 59px;
  position: absolute;
  right: 20rem;
  top: 4rem;
  }
   */
   .side-b{
    position: absolute;
    top: 19rem;
    right: 17rem;
  }
}

@media screen and (max-width:1100px){
  .side-b{
    position: absolute;
    top: 19rem;
    right: 14rem;
  }
}
@media screen and (max-width:1050px){
  .list  li .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 6rem;
    left: 64%;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
}
@media screen and (max-width:991px) {
  .bg1 {
    position: absolute;
    background-image: url("../../images/bg1.png");
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 120px;
    height: 2059.16px;
  }
  
  .bg2 {
    position: absolute;
    background: url("../../images/bg2.png");
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 268px;
    color: white;
    display: flex;
    flex-direction: column;
    height: 1600.33px;
  }
  .bg3 {
    position: absolute;
    background: url("../../images/bg3.png");
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%;
    height: 100%;
    left: 100px;
    top: 420px;
    height: 1231.24px;
  }
  .gold-text{
    position: relative;
    top: 1.7rem;
    left: -6.5rem;

}


.play-store{
  position: relative;
  top: 0rem;
  left: -6.5rem;
}
  .coin-main{
    position: relative;
    width: 75.13px;
  height: 75.13px;
  left: -22rem;
  top: -26rem;
  }
  
  .coin-main-r{
    position: relative;
    width: 65px;
  height: 65px;
  right: -20rem;
  top: -35rem;
  }
  
  .credit-m{
    position: relative;
    width: 99.67px;
    height: 99.67px;
    left: -26rem;
    top: -18rem;
  }
  
  .paper-b{
    position: relative;
    width: 99.67px;
    height: 99.67px;
    right: -4rem;
    top: -23rem;
  }
  
  .gold-coin{
    width: 58px;
  height: 58px;
  position: absolute;
  left: 10rem;
  top: 12rem;
  }
  
  .blue-coin{
    width: 59px;
  height: 59px;
  position: absolute;
  right: 20rem;
  top: 10rem;
  }
  .side-l{
    position: absolute;
    top: -6.5rem;
    right: 1rem;
    z-index: 0;
  }
 
  .side-r{
    position: absolute;
  top: 13rem;
  left: -7rem;
  z-index: 0;
  } 
  
}
@media screen and (max-width:860px){
  .list  li .play-store{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 6rem;
    left: 54%;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
}

@media screen and (max-width:830px) {
  .nav-bar {
    background: #0e0c21;
    box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.06);
    border-radius: 70px;
    width: 90%;
    height: 75px;
    position: relative;
    left: 5%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 2rem;
  
  }
  .coin-main{
    position: relative;
    width: 75.13px;
  height: 75.13px;
  left: -15rem;
  top: -26rem;
  }
  
  .coin-main-r{
    position: relative;
    width: 65px;
  height: 65px;
  right: -16rem;
  top: -36rem;
  }
  
  .credit-m{
    position: relative;
    width: 99.67px;
    height: 99.67px;
    left: -22rem;
    top: -19rem;
  }
  
  .paper-b{
    position: relative;
    width: 99.67px;
    height: 99.67px;
    right: -3rem;
    top: -26rem;
  }
  
  .gold-coin{
    width: 58px;
  height: 58px;
  position: absolute;
  left: 6rem;
  top: 10rem;
  }
  
  .blue-coin{
    width: 59px;
  height: 59px;
  position: absolute;
  right: 13rem;
  top: 4rem;
  }
  
}
@media screen and (max-width:700px) {
  .logo-text-2{
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    
    /* identical to box height */
  
    color: #000000;
  }
  .coin-main{
    position: relative;
    width: 55.13px;
  height: 55.13px;
  left: -15rem;
  top: -26rem;
  /* display: none; */
  }
  
  .coin-main-r{
    position: relative;
    width: 45px;
  height: 45px;
  right: -10rem;
  top: -34rem;
    /* display: none; */
}
  
  .credit-m{
    position: relative;
    width: 45.67px;
    height: 45.67px;
    left: -20rem;
    top: -17rem;
  }
  
  .paper-b{
    position: relative;
    width: 55.67px;
    height: 55.67px;
    right: -3rem;
    top: -23rem;
  }
  
  .gold-coin{
    width: 38px;
  height: 38px;
  position: absolute;
  left: 3rem;
  top: 12rem;
  }
  
  .blue-coin{
    width: 39px;
  height: 39px;
  position: absolute;
  right: 13rem;
  top: 2rem;
  }


  .menu-items{
    position: fixed;
    width: 60rem;
    height: 60rem;
    top: 0;
    left: -5rem;
    right: 0;
    background: white;
    opacity: 1;
    z-index: 2;
  }
  .menu-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12rem;
    right: 4rem;
  }
  .menu-items div li {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    list-style: none;
    opacity: 1;
    /* identical to box height */
  
    /* grey scale light/4 */
    
  }
  .menu-items div li a{
    color: #000000;
  }
  .btn-app span{
    color: #FFFFFF ;
  }
  .menu-icon {
      display: block;
      z-index: 5;
      cursor: pointer;
      width: 24px;
      height: 24px;
  }
  .side-b{
    position: absolute;
    top: 19rem;
    right: 8rem;
  }



}

@media screen and (max-width:620px){
  .menu-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12rem;
    right: 7rem;
  }
  .coin-main-r{
    position: relative;
    width: 45px;
  height: 45px;
  right: -9rem;
  top: -34rem;
    /* display: none; */
}
  .side-b{
    position: absolute;
    top: 19rem;
    right: 5rem;
  }
  .play-store-icon{
    background: #0E0C21;
    box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
    border-radius: 13px;
    width: 155px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .play-store-icon img{
  width: 120px;
  height: 40px;
  }
  
  .ios-store{
    background: #0E0C21;
  box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
  border-radius: 13px;
  width: 155px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .ios-store img{
    width: 120px;
  height: 40px;
  }
}

@media screen and (max-width:520px) {
 
  .menu-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12rem;
    right: 9rem;
  }
  .coin-main{
    position: relative;
    width: 75.13px;
  height: 75.13px;
  left: -15rem;
  top: -26rem;
  display: none;
  }
  
  .coin-main-r{
    position: relative;
    width: 65px;
  height: 65px;
  right: -16rem;
  top: -29rem;
    display: none;
}
  
  .credit-m{
    position: relative;
    width: 45.67px;
    height: 45.67px;
    left: -14rem;
    top: -19rem;
  }
  
  .paper-b{
    position: relative;
    width: 55.67px;
    height: 55.67px;
    right: -3rem;
    top: -23rem;
  }
  
  .gold-coin{
    width: 38px;
  height: 38px;
  position: absolute;
  left: -6rem;
  top: 7rem;
  }
  
  .blue-coin{
    width: 39px;
  height: 39px;
  position: absolute;
  right: 10rem;
  top: 2rem;
  }
  .side-l{
    position: absolute;
    top: -6.5rem;
    right: 2rem;
    z-index: 0;
  }
  .side-r{
    position: absolute;
  top: 6rem;
  left: -10rem;
  z-index: 0;
  }
}

@media screen and (max-width:450px){

  .menu-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12rem;
    right: 12rem;
  }
  .nav-bar {
    background: transparent;
    box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.06);
    border-radius: 70px;
    width: 100%;
    height: 75px;
    position: relative;
    left: 0%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 2rem;
  
  }
  .side-b{
    position: absolute;
    top: 19rem;
    right: 1rem;
  }
  
}
@media screen and (max-width:400px) {
  .gold-coin{
    width: 38px;
  height: 38px;
  position: absolute;
  left: -6rem;
  top: 8rem;
  }
  
  .blue-coin{
    width: 39px;
  height: 39px;
  position: absolute;
  right: 10rem;
  top: -1rem;
  }
  .side-l{
    position: absolute;
    top: -6.5rem;
    right: 2rem;
    z-index: 0;
  }
  .side-r{
    position: absolute;
  top: 6rem;
  left: -10rem;
  z-index: 0;
  }
  .side-b{
    position: absolute;
    top: 19rem;
    right: 0rem;
  }
}
@media screen and (max-width:377px){
  
  .menu-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12rem;
    right: 14rem;
  }
  .nav-bar {
    background: transparent;
    box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.06);
    border-radius: 70px;
    width: 100%;
    height: 75px;
    position: relative;
    left: 0%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 2rem;
  
  }
  .paper-b{
    position: relative;
    width: 55.67px;
    height: 55.67px;
    right: -1rem;
    top: -26rem;
  }
  
  .side-l{
    position: absolute;
    top: -6.5rem;
    right: 0rem;
    z-index: 0;
  }
  .side-b{
    position: absolute;
    top: 19rem;
    right: 0rem;
    display: none;
  }
 
}

@media screen and (max-width:340px){
  .credit-m{
    display: none;
  }
  .paper-b{
    display: none;
  }
  .play-store-icon{
    background: #0E0C21;
    box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
    border-radius: 13px;
    width: 135px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .play-store-icon img{
  width: 115px;
  height: 30px;
  }
  
  .ios-store{
    background: #0E0C21;
  box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
  border-radius: 13px;
  width: 135px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .ios-store img{
    width: 115px;
  height: 30px;
  }
}
@media screen and (max-width:320px){
  
  .menu-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12rem;
    right: 16rem;
  }
  .nav-bar {
    background: transparent;
    box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.06);
    border-radius: 70px;
    width: 100%;
    height: 75px;
    position: relative;
    left: 0%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 1rem;
  
  }
  .side-b{
    position: absolute;
    top: 19rem;
    right: -2rem;
  }
  .play-store-icon{
    background: #0E0C21;
    box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
    border-radius: 13px;
    width: 125px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .play-store-icon img{
  width: 105px;
  height: 30px;
  }
  
  .ios-store{
    background: #0E0C21;
  box-shadow: 0px 4px 31px rgba(57, 57, 57, 0.11);
  border-radius: 13px;
  width: 125px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .ios-store img{
    width: 105px;
  height: 30px;
  }
  
}
@media screen and (max-width:280px){
  .menu-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12rem;
    right: 17rem;
  }
  .nav-bar {
    background: transparent;
    box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.06);
    border-radius: 70px;
    width: 100%;
    height: 75px;
    position: relative;
    left: 0%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 1rem;
  
  }
  
}