@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

.home-sec{
    padding-top: 10rem;
    padding-bottom: 7rem;
    background: #13122B;
    overflow: hidden;
    position: relative;
    z-index: 2;
    /* transform: matrix(0.99, -0.1, 0.14, 0.99, 0, 0); */
}
.home-sec::before{
    background: #F5F5F5;
bottom: 0;
content: "";
height: 8rem;
left: 0;
position: absolute;
right: 0;
top: -7rem;
-webkit-transform: skewY(-4deg);
transform: skewY(-4deg);
-webkit-transform-origin: top right;
transform-origin: top right;
width: 100%;
z-index: 2;
border-bottom: 0.5rem solid #80D1FF;
}

/* .slanted{
    position: absolute;
    top: 125rem;
    width: 100%;
    left: -1rem;
    background: #80D1FF;
transform: matrix(-0.99, 0.12, -0.12, -0.99, 0, 0);
} */
.heading{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.heading span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 104.12%;
/* identical to box height, or 15px */

letter-spacing: 0.465em;
text-transform: uppercase;

color: #80D1FF;
}
.heading h1{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 81px;
color: #ffff;
width: 673px;
height: 81px;
}


.inner-box{
    /* border: #f8f8f8 1px solid; */
    display: flex;
    /* margin-top: 10rem; */
    justify-content: space-around;
}
.text{
    flex: 1.6;
    /* border: #d60000 1px solid; */
}
.text-2{
    flex: 1;
    /* border: #e40707 1px solid; */
    padding: 0 5rem;
}

.text h1 {
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 42px;
line-height: 58px;

/* or 138% */

color: #E6E6E6;


}

.text h1 span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 42px;
line-height: 58px;
/* identical to box height, or 138% */


color: #FFC852;

}

.text-2 p{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    
    /* or 200% */
    letter-spacing: 0.01em;
    
    color: rgba(255, 255, 255, 0.7);
    text-align: justify;
    
}

.dis-btn{
    width: 170px;
    height: 58px;
    background: #4E48B1;
    border-radius: 47px;
    border: none;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 104.12%;
    
    /* identical to box height, or 17px */
    letter-spacing: 0.01em;
    text-transform: capitalize;
    
    color: #FFFFFF;
}


.dis-btn .play-store{
  display: flex;
  gap: 1rem;
  position: absolute;
  top: 110%;
  left: 25%;
  z-index: 9;
  opacity: 3;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.least-inner-box{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.overlay{
width: 90vw;
height: 113px;
/* display: none;   */
position: absolute;
background: linear-gradient(180deg, #1D1B41 0%, rgba(25, 24, 58, 0.4) 130.62%);
border-radius: 77px;
top: 21rem;
left: 3.5rem;
opacity: 0.7;
}
/* 
@keyframes scroll {
    0%{
        transform: translateX(calc(250*7));
    }
    100%{
        transform: translateX(calc(-250px * 7));
    }
}
.slider{
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.slider .slide-track{
    animation: scroll 10s linear infinite;
    display: flex;
    width: calc(250px * 7);
} */

/* .slide{

width: 250px;
height: 100px;




background: #E8E8EB;
border-radius: 43px;
} */



.slider{
    padding: 5rem 3rem;
}

.item {
    background: #F3F3F5;
border-radius: 43px;
    width: 206px;
height: 66px;
display: flex;
align-items: center;
justify-content: center;
gap: 1rem;
}

.carousel-img{
    width: 49px;
height: 49px;
    background-color: #FFFFFF;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.carousel-img img{
    width: 45px;
    height: 40px;
}
.item span{

font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.01em;

color: #000000;
}

.item p{

font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;

/* Primary/2 */

color: #4E48B1;

}

/* .react-multi-carousel-item{
    max-width: 230px;
    min-width:230px;
} */

.img-ellipse1{
    position: absolute;
    left: 20rem;
    top: 7rem;
    width: 56px;
    height: 56px;
}
.img-ellipse2{
    position: absolute;
    right: 33rem;
    top: 7rem;
    width: 56px;
    height: 56px;
}
.img-ellipse3{
    width: 70px;
    height: 70px;
    position: absolute;
    left: 12rem;
    top: 26.6rem;
}
.img-ellipse4{
    position: absolute;
    right: 10rem;
    top: 18.5rem;
    height: 70px;
    width: 70px;
    z-index: 1;
}

@media only screen and (max-width: 1200px){
    .img-ellipse1{
        position: absolute;
        left: 15rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 30rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
}
@media only screen and (max-width: 1070px){
    .img-ellipse1{
        position: absolute;
        left: 12rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 25rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
    .dis-btn .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 110%;
        left: 17%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
      
}

@media only screen and (max-width: 991px){
    .overlay{
        width: 120vw;
        height: 113px;
        /* display: none;   */
        position: absolute;
        background: linear-gradient(180deg, #1D1B41 0%, rgba(25, 24, 58, 0.4) 130.62%);
        border-radius: 77px;
        top: 20.5rem;
        left: -4rem;
        opacity: 0.7;
        }
        .img-ellipse1{
            position: absolute;
            left: 9rem;
            top: 7rem;
            width: 56px;
            height: 56px;
        }
        .img-ellipse2{
            position: absolute;
            right: 21rem;
            top: 7rem;
            width: 56px;
            height: 56px;
        }
        .img-ellipse3{
            width: 70px;
            height: 70px;
            position: absolute;
            left: 10rem;
            top: 26rem;
        }
        .img-ellipse4{
            position: absolute;
            right: 8rem;
            top: 18.5rem;
            height: 70px;
            width: 70px;
            z-index: 1;
        }
}
@media only screen and (max-width: 900px){
    .img-ellipse1{
        position: absolute;
        left: 4rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 15rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse3{
        width: 70px;
        height: 70px;
        position: absolute;
        left: 5rem;
        top: 26rem;
    }
    .img-ellipse4{
        position: absolute;
        right: 4rem;
        top: 18.5rem;
        height: 70px;
        width: 70px;
        z-index: 1;
    }
    .dis-btn .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 110%;
        left: 10%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
      
}
@media only screen and (max-width: 800px){
  
        .item{
            width: 170.55px;
height: 53.35px;
        }
        .item span{
            font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 12.9327px;
line-height: 16px;
letter-spacing: 0.01em;

color: #000000;


        }
        .item p{
            font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 9.69956px;
line-height: 12px;

/* Primary/2 */
color: #4E48B1;


        }
        .carousel-img{
            width: 39.61px;
height: 39.61px;
        }
}
@media screen and (max-width: 768px){
    .home-sec{
        padding-top: 10rem;
        padding-bottom: 5rem;
        background: #13122B;
        overflow: hidden;
        position: relative;
        z-index: 2;
        /* transform: matrix(0.99, -0.1, 0.14, 0.99, 0, 0); */
    }
    .shopping-head h1{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700 !important;
font-size: 38px !important;
line-height: 48px !important;
text-align: center !important;
letter-spacing: 0.01em !important;

color: #FFFFFF !important;


    }
    .img-ellipse1{
        position: absolute;
        left: 5rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 10rem;
        top: 7rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse3{
        width: 70px;
        height: 70px;
        position: absolute;
        left: 5rem;
        top: 26rem;
    }
    .img-ellipse4{
        position: absolute;
        right: 4rem;
        top: 18.5rem;
        height: 70px;
        width: 70px;
        z-index: 1;
    }
    .dis-btn .play-store{
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 110%;
        left: 7%;
        z-index: 9;
        opacity: 3;
        visibility: visible;
        transform: translateY(0);
        transition: var(--speed) ease;
      }
      
}



@media only screen and (max-width: 600px){
    .img-ellipse1{
        position: absolute;
        left: 5rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 5rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse3{
        width: 70px;
        height: 70px;
        position: absolute;
        left: 10rem;
        top: 26.6rem;
        display: none;
    }
    .img-ellipse4{
        position: absolute;
        right: 4rem;
        top: 31.5rem;
        height: 70px;
        width: 70px;
        z-index: 1;
    }

    .overlay{
        width: 150vw;
        height: 113px;
        /* display: none;   */
        position: absolute;
        background: linear-gradient(180deg, #1D1B41 0%, rgba(25, 24, 58, 0.4) 130.62%);
        border-radius: 77px;
        top: 20.5rem;
        left: -4rem;
        opacity: 0.7;
        }
    
        .inner-box{
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .text h1{
            text-align: left;
            font-size: 30px;
        }

        .text h1 span{
            font-size: 30px;
        }
        .text-2{
            flex: 1;
            /* border: #e40707 1px solid; */
            padding: 0 5rem;
        }
}
@media only screen and (max-width: 490px){
    .img-ellipse1{
        position: absolute;
        left: 3rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 3rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse3{
        width: 70px;
        height: 70px;
        position: absolute;
        left: 10rem;
        top: 26.6rem;
        display: none;
    }
    .img-ellipse4{
        position: absolute;
        right: 1rem;
        top: 31.5rem;
        height: 70px;
        width: 70px;
        z-index: 1;
    }
    .text-2{
        flex: 1;
        /* border: #e40707 1px solid; */
        padding: 0 4rem;
    }
    
        .inner-box{
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .text h1{
            font-size: 30px;
            text-align: center;
        }

        .text h1 span{
            font-size: 30px;
        }
        .text-2 p{
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            
            /* or 200% */
            text-align: center;
            letter-spacing: 0.01em;
            
            /* secondary/grey scale/5 */
            color: #9496A0;
            
            
        }
        .myntra{
            width: 25px;
            height: 20px;
        }
        
        .z{
            width: 30px;
        height: 35px;
        }
}

@media screen and (max-width:450px){
    .text-2{
        flex: 1;
        /* border: #e40707 1px solid; */
        padding: 0 3rem;
    }
}
@media screen and (max-width:420px){
    .text-2{
        flex: 1;
        /* border: #e40707 1px solid; */
        padding: 0 2rem;
    }
    .img-ellipse1{
        position: absolute;
        left: 1rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 1rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse3{
        width: 70px;
        height: 70px;
        position: absolute;
        left: 10rem;
        top: 26.6rem;
        display: none;
    }
    .img-ellipse4{
        position: absolute;
        right: -1rem;
        top: 31.5rem;
        height: 70px;
        width: 70px;
        z-index: 1;
    }
}
@media screen and (max-width:400px){
    .text-2{
        flex: 1;
        /* border: #e40707 1px solid; */
        padding: 0 1rem;
    }
    .carousel-img img{
        width: 35px;
        height: 30px;
    }
   
}

@media screen and (max-Width:377px){
    .img-ellipse1{
        position: absolute;
        left: 0rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse2{
        position: absolute;
        right: 0rem;
        top: 14rem;
        width: 56px;
        height: 56px;
    }
    .img-ellipse3{
        width: 70px;
        height: 70px;
        position: absolute;
        left: 10rem;
        top: 26.6rem;
        display: none;
    }
    .img-ellipse4{
        position: absolute;
        right: -1rem;
        top: 31.5rem;
        height: 70px;
        width: 70px;
        z-index: 1;
    }
    .overlay{
        width: 200vw;
        height: 113px;
        /* display: none;   */
        position: absolute;
        background: linear-gradient(180deg, #1D1B41 0%, rgba(25, 24, 58, 0.4) 130.62%);
        border-radius: 77px;
        top: 20.5rem;
        left: -4rem;
        opacity: 0.7;
        }
    
    .item{
        width: 125.55px;
height: 53.35px;
    }
    .item span{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 9.9327px;
line-height: 16px;
letter-spacing: 0.01em;

color: #000000;


    }
    .item p{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 7.69956px;
line-height: 12px;

/* Primary/2 */
color: #4E48B1;


    }
    .carousel-img{
        width: 33.61px;
height: 33.61px;
    }
    .myntra{
        width: 25px;
        height: 20px;
    }
    .item{
        gap: 0.5rem;
    }
}

@media screen and (max-Width:343px){
    .img-ellipse4{
        position: absolute;
        right: 0rem;
        top: 35.5rem;
        height: 70px;
        width: 70px;
        z-index: 1;
    }
}