@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

.explore{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    padding-bottom: 7rem;
    padding-top: 5rem;
    position: relative;
    /* overflow: hidden; */
    z-index: 3;
    background: #F5F5F5;
}
.explore::before{
    background: #F5F5F5;
bottom: 0;
content: "";
height: 8rem;
left: 0;
position: absolute;
right: 0;
top: -7rem;
-webkit-transform: skewY(-4deg);
transform: skewY(-4deg);
-webkit-transform-origin: top right;
transform-origin: top right;
width: 100%;
z-index: 2;
}

.explore-phone{
    width: 501.02px;
height: 509.9px;
}

.explore-upper{
    display: flex;
    flex-direction: column;
    gap: 3rem;    
}

.explore-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    
}

.smart{
    position: absolute;
    right: 36%;
    top: 12.5%;
}

.explore-heading> span{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 11px;
    
    /* identical to box height, or 79% */
    text-align: center;
    letter-spacing: 0.3em;
    
    color: #4E48B1;
}

.explore-heading h1{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 53px;

/* identical to box height */
text-align: center;
letter-spacing: 0.03em;

color: #000000;


}

.explore-heading>p{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 41px;

/* identical to box height, or 228% */
text-align: center;
letter-spacing: 0.02em;

/* secondary/grey scale/5 */
color: #9496A0;


}
.explore-footer{
    display: flex;
    align-items: center;
    gap: 5rem;
    justify-content: center;
}

.explore-arch{
    position: absolute;
    top: 23%;
    left: 29%;
    margin-left: auto;
margin-right: auto;
}
.explore-arch-border{
    position: absolute;
    margin-left: auto;
margin-right: auto;
top: 23.5%;
left: 28.3%;
}
.explore-arch-border2{
    position: absolute;
    top: 55%;
    left: 75%;
    margin-left: auto;
margin-right: auto;
}

.star-blue{
    position: absolute;
    top: 55%;
    left: 29%;
    margin-left: auto;
margin-right: auto;
}
.star-gold{
    position: absolute;
    top: 37%;
    right: 24%;
    margin-left: auto;
margin-right: auto;
}
.explore-circle{
    position: absolute;
    top: 30%;
    left: 51%;
    margin-left: auto;
margin-right: auto;
}
.explore-circle2{
    position: absolute;
    top: 44%;
    left: 15%;
    margin-left: auto;
margin-right: auto;
}
.explore-footer h1{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 30px;
text-align: left;

/* secondary/grey scale/1 */
color: #323549;


}



.explore-footer p{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 26px;

/* or 166% */
letter-spacing: 0.01em;

/* secondary/grey scale/4 */
color: #7C7E8B;
width: 330px;
height: 106px;

}

.explore-footer-1{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.explore-footer-2{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.explore-footer-3{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.explore-footer-1 div >img{
    width: 85px;
height: 84px;
}
.explore-footer-2 div >img{
    width: 98px;
    height: 90px;
}
.explore-footer-3 div >img{
    width: 74px;
height: 68px;
}
.arch{
    width: 51px;
    height: 50px;
}
@media screen and (max-width:1200px) {
    .explore-footer{
        display: flex;
        align-items: center;
        gap: 2rem !important;
    }
    /* .explore-arch{
        position: absolute;
        top: 18.5rem;
        left: 21.2rem;
        margin-left: auto;
    margin-right: auto;
    }
    .explore-arch-border{
        position: absolute;
        margin-left: auto;
    margin-right: auto;
    top: 18.8rem;
    left: 20.5rem;
    }
    .explore-arch-border2{
        position: absolute;
        top: 43rem;
        left: 60rem;
        margin-left: auto;
    margin-right: auto;
    } */
    .smart{
        position: absolute;
        right: 34%;
        top: 12.5%;
    }
    .star-blue{
        position: absolute;
        top: 55%;
        left: 25%;
        margin-left: auto;
    margin-right: auto;
    }
    .star-gold{
        position: absolute;
        top: 37%;
        right: 24%;
        margin-left: auto;
    margin-right: auto;
    }
    .explore-circle{
        position: absolute;
        top: 30%;
        left: 51%;
        margin-left: auto;
    margin-right: auto;
    }
    .explore-circle2{
        position: absolute;
        top: 44%;
        left: 10%;
        margin-left: auto;
    margin-right: auto;
    }
}
@media screen and (max-width:1100px) {
    .explore-footer{
        display: flex;
        align-items: center;
        gap: 1rem !important;
    }
}
@media screen and (max-width:1050px) {
    .explore-upper{
        gap: 5rem !important;
    }
    .explore-phone{
        width: 299px;
height: 304.3px;
    }
    .explore-heading>h1{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.02em;

color: #000000;


    }
    .explore-heading>p{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        
        /* secondary/grey scale/4 */
        color: #7C7E8B;
    
    }
    .explore-footer{
        display: flex;
        align-items: center;
        gap: 3rem !important;
        flex-direction: column;
    }
    .explore-footer h1{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        
        /* secondary/grey scale/1 */
        color: #323549;
        
        
    
    
    }
    .explore-footer p{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 26px;

/* or 166% */
text-align: center;
letter-spacing: 0.01em;


    }
    .explore-footer-1, .explore-footer-2, .explore-footer-3{
        text-align: center !important;
    }
    .explore-upper{
        display: flex;
        flex-direction: column-reverse;
    }

    .smart{
        display: none;
    }

    .star-blue{
        display: none;
    }
    .star-gold{
        display: none;
    }
    .explore-circle{
        display: none;
    }
    .explore-circle2{
        display: none;
    }
.explore-arch{
    position: absolute;
    top: 4%;
    left: 29%;
    margin-left: auto;
margin-right: auto;
display: block !important;
}
.explore-arch-border{
    position: absolute;
    margin-left: auto;
margin-right: auto;
top: 4.5%;
left: 28.3%;
display: block !important;
}
.explore-arch-border2{
    position: absolute;
    top: 23%;
    left: 73%;
    margin-left: auto;
margin-right: auto;
display: block !important;
}

}

@media screen and (max-width:700px){
    .explore-arch{
        position: absolute;
        top: 4%;
        left: 25%;
        margin-left: auto;
    margin-right: auto;
    display: block !important;
    }
    .explore-arch-border{
        position: absolute;
        margin-left: auto;
    margin-right: auto;
    top: 4.5%;
    left: 23.3%;
    display: block !important;
    }
    .explore-arch-border2{
        position: absolute;
        top: 23%;
        left: 77%;
        margin-left: auto;
    margin-right: auto;
    display: block !important;
    }
    .explore-heading p{
        padding: 0 2rem;
    }
    
}
@media screen and (max-width:520px){
    .explore-heading p{
        padding: 0 2.5rem;
    }
    
    .explore-arch{
        position: absolute;
        top: 4%;
        left: 18%;
        margin-left: auto;
    margin-right: auto;
    display: block !important;
    }
    .explore-arch-border{
        position: absolute;
        margin-left: auto;
    margin-right: auto;
    top: 4.5%;
    left: 16.3%;
    display: block !important;
    }
    .explore-arch-border2{
        position: absolute;
        top: 23%;
        right: 1%;
        margin-left: auto;
    margin-right: auto;
    display: block !important;
    }
    
}

@media  screen and (max-width:350px) {
    .explore{
        padding-top: 4rem !important;
    }
    .explore-footer{
        display: flex;
        align-items: center;
        gap: 3rem !important;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .explore-footer-1, .explore-footer-2, .explore-footer-3{
        text-align: center !important;
        padding: 0 2rem !important;
    }
    .explore-footer h1{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        
        /* secondary/grey scale/1 */
        color: #323549;
        
        
    
    
    }
    .explore-footer p{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 25px;
padding: 0 2rem;
/* or 166% */
text-align: center;
letter-spacing: 0.01em;

    }
    .explore-heading>p{
        padding:  0 1rem !important;
    }

    
}

@media  screen and (max-width:320px){
    .explore-upper{
        gap: 2rem !important;
    }
    .explore-phone{
        width: 259px !important;
height: 250.3px !important;
    }
}
@media  screen and (max-width:320px){
    .explore-upper{
        gap: 2rem !important;
    }
    .explore-phone{
        width: 229px !important;
height: 230.3px !important;
    }
    .explore-arch{
        position: absolute;
        top: 3%;
        left: 14%;
        margin-left: auto;
    margin-right: auto;
    display: block !important;
    width: 32px;
    }
    .explore-arch-border{
        position: absolute;
        margin-left: auto;
    margin-right: auto;
    top: 3.5%;
    left: 12.3%;
    display: block !important;
        width: 32px;
    }
    .explore-arch-border2{
        position: absolute;
        top: 19%;
        right: 1%;
        margin-left: auto;
    margin-right: auto;
    display: block !important;
    width: 32px;
    }
}