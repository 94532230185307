@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap");
.body-sec {
  display: flex;
  flex-direction: column;
  padding: 5rem 8rem;
  background: rgb(0, 0, 39);
  gap: 0rem;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.scrolled{
  display: none;
}
.body-l{
    margin-bottom: -5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.body-l span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #e6e6e6;

}

.body-r {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.body-r .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.box span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  /* Grey Scale Dark/5 */

  color: #E6E6E6;
}

.box p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  text-align: center;
  letter-spacing: 0.01em;

  color: rgba(255, 255, 255, 0.7);
}

.box2 {
  position: relative;
  top: -143px;
}

.line1 {
  background: url("../../images/line1.png");
  /* border: 1px dashed transparent; */
  width: 298px;
  height: 206.95px;
  position: relative;
  top: 22rem;
  left: 12rem;
  background-repeat: no-repeat;
}
.line2 {
  background: url("../../images/line2.png");
  /* border: 1px dashed transparent; */
  width: 298px;
  height: 206.95px;
  position: relative;
  left: 37rem;
  top: 9rem;
  background-repeat: no-repeat;
}

.box-img{
    z-index: 1;
    transform: rotate(180deg);
    transform: rotate(0deg);
    
}
.box-img img{
  width: 148.83px;
height: 148.83px;
}
.money-counting{
    position: absolute;
    left: 8rem;
    top: 20rem;
    width: 47px;
height: 47px;
}

.paper-bag{
    position: absolute;
    top: 44rem;
    left: 43rem;
    width: 61.72px;
height: 61.72px;

}

.ellipse1-b{
  position: absolute;
  width: 60px;
  height: 60px;
  left: 25rem;
  top: 20rem;
}
.ellipse2-b{
  position: absolute;
  width: 60px;
height: 60px;
right: 27rem;
top: 15rem;
}
.ellipse3-b{
  position: absolute;
  width: 45px;
  height: 45px;
  right: 5rem;
  top: 30rem;
}
.ellipse4-b{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50rem;
  left: 30rem;
}
.gold-biscuit{
    position: absolute;
    right: 10rem;
    top: 15rem;
    
}

.handcoin{
  position: absolute;
  top: 4.2rem;
  left: 6.3rem;
  width: 46px !important;
height: 23.74px  !important;
}

.arrowhand{
  position: absolute;
  top: 3.5rem;
  left: 6.5rem;
  width: 32.37px !important;
height: 32.37px !important;
}

.gold-hand1{
  position: absolute;
  width: 26.97px !important;
height: 26.97px !important  ;
left: 0.7rem;
top: 1rem;
}
.gold-hand2{
  position: absolute;
  width: 17.5px !important;
height: 17.5px !important;
left: 7rem;
top: 1.5rem;
}
.gold-hand3{
  position: absolute;
  width: 20.52px !important;
height: 20.52px !important;
top: 4.5rem;
left: 7.6rem;
}
@media screen and (max-width: 1300px) {
  .body-sec {
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem;
    background: rgb(0, 0, 39);
    gap: 0rem;
  }
  .line1 {
    background: url("../../images/line1.png");
    /* border: 1px dashed transparent; */
    width: 298px;
    height: 206.95px;
    position: relative;
    top: 22rem;
    left: 16rem;
    background-repeat: no-repeat;
  }
  .line2 {
    background: url("../../images/line2.png");
    /* border: 1px dashed transparent; */
    width: 298px;
    height: 206.95px;
    position: relative;
    left: 42rem;
    top: 9rem;
    background-repeat: no-repeat;
  }
  /* .money-counting{
    position: relative;
    right: 25rem;
    top: 20rem;
}

.paper-bag{
    position: relative;
    top: 48rem;
    left: 3rem;
}

.gold-biscuit{
    position: relative;
    left: 28rem;
    top: 15rem;
} */
}
@media screen and (max-width:1250px){
  .line1 {
    background: url("../../images/line1.png");
    /* border: 1px dashed transparent; */
    width: 298px;
    height: 206.95px;
    position: relative;
    top: 22rem;
    left: 15rem;
    background-repeat: no-repeat;
  }
  .line2 {
    background: url("../../images/line2.png");
    /* border: 1px dashed transparent; */
    width: 298px;
    height: 206.95px;
    position: relative;
    left: 42rem;
    top: 9rem;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width:1150px){
    .line1 {
        background: url("../../images/line1.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        top: 22rem;
        left: 10rem;
        background-repeat: no-repeat;
      }
      .line2 {
        background: url("../../images/line2.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        left: 37rem;
        top: 9rem;
        background-repeat: no-repeat;
      }
      .money-counting{
        position: absolute;
        left: 5rem;
        top: 20rem;
        width: 47px;
    height: 47px;
    }
    
    .paper-bag{
        position: absolute;
        top: 44rem;
        left: 36rem;
        width: 61.72px;
    height: 61.72px;
    
    }
    
    .ellipse1-b{
      position: absolute;
      width: 60px;
      height: 60px;
      left: 20rem;
      top: 20rem;
    }
    .ellipse2-b{
      position: absolute;
      width: 60px;
    height: 60px;
    right: 23rem;
    top: 15rem;
    }
    .ellipse3-b{
      position: absolute;
      width: 45px;
      height: 45px;
      right: 2rem;
      top: 30rem;
    }
    .ellipse4-b{
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50rem;
      left: 22rem;
    }
}
@media screen and (max-width:1070px){
    .line1 {
        background: url("../../images/line1.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        top: 22rem;
        left: 8.5rem;
        background-repeat: no-repeat;
      }
      .line2 {
        background: url("../../images/line2.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        left: 35rem;
        top: 9rem;
        background-repeat: no-repeat;
      }
}
@media screen and (max-width:991px){
    .line1 {
        background: url("../../images/line1.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        top: 22rem;
        left: 6rem;
        background-repeat: no-repeat;
      }
      .line2 {
        background: url("../../images/line2.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        left: 32rem;
        top: 9rem;
        background-repeat: no-repeat;
      }

      .money-counting{
        position: absolute;
        left: 3rem;
        top: 20rem;
        width: 47px;
    height: 47px;
    }
    
    .paper-bag{
        position: absolute;
        top: 44rem;
        left: 32rem;
        width: 61.72px;
    height: 61.72px;
    
    }
    
    .ellipse1-b{
      position: absolute;
      width: 60px;
      height: 60px;
      left: 15rem;
      top: 20rem;
    }
    .ellipse2-b{
      position: absolute;
      width: 60px;
    height: 60px;
    right: 15rem;
    top: 15rem;
    }
    .ellipse3-b{
      position: absolute;
      width: 45px;
      height: 45px;
      right: 1rem;
      top: 30rem;
    }
    .ellipse4-b{
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50rem;
      left: 20rem;
    }

    .gold-biscuit{
        position: absolute;
        right: 5rem;
        top: 15rem;
        
    }
    
     
}
@media screen and (max-width:920px){
    .line1 {
        background: url("../../images/line1.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        top: 22rem;
        left: 5rem;
        background-repeat: no-repeat;
      }
      .line2 {
        background: url("../../images/line2.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        left: 29rem;
        top: 9rem;
        background-repeat: no-repeat;
      }
      .money-counting{
        position: absolute;
        left: 3rem;
        top: 20rem;
        width: 47px;
    height: 47px;
    }
    
    .paper-bag{
        position: absolute;
        top: 44rem;
        left: 28rem;
        width: 61.72px;
    height: 61.72px;
    
    }
    
    .ellipse1-b{
      position: absolute;
      width: 60px;
      height: 60px;
      left: 15rem;
      top: 20rem;
    }
    .ellipse2-b{
      position: absolute;
      width: 60px;
    height: 60px;
    right: 15rem;
    top: 15rem;
    }
    .ellipse3-b{
      position: absolute;
      width: 45px;
      height: 45px;
      right: 1rem;
      top: 30rem;
    }
    .ellipse4-b{
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50rem;
      left: 20rem;
    }

    .gold-biscuit{
        position: absolute;
        right: 5rem;
        top: 15rem;
        
    }
}
@media screen and (max-width:830px){
    .line1 {
        background: url("../../images/line1.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        top: 22rem;
        left: 3rem;
        background-repeat: no-repeat;
      }
      .line2 {
        background: url("../../images/line2.png");
        /* border: 1px dashed transparent; */
        width: 298px;
        height: 206.95px;
        position: relative;
        left: 26rem;
        top: 9rem;
        background-repeat: no-repeat;
      }

}

@media screen and (max-width: 768px) {
  .line1 {
    background: url("../../images/line1.png");
    /* border: 1px dashed transparent; */
    width: 298px;
    height: 206.95px;
    position: relative;
    top: 22rem;
    left: 1rem;
    background-repeat: no-repeat;
  }
  .line2 {
    background: url("../../images/line2.png");
    /* border: 1px dashed transparent; */
    width: 298px;
    height: 206.95px;
    position: relative;
    left: 23rem;
    top: 9rem;
    background-repeat: no-repeat;
  }
  .money-counting{
    position: absolute;
    left: 1rem;
    top: 20rem;
    width: 47px;
height: 47px;
}

.paper-bag{
    position: absolute;
    top: 47rem;
    left: 20rem;
    width: 61.72px;
height: 61.72px;

}

.ellipse1-b{
  position: absolute;
  width: 60px;
  height: 60px;
  left: 8rem;
  top: 20rem;
}
.ellipse2-b{
  position: absolute;
  width: 60px;
height: 60px;
right: 12rem;
top: 15rem;
}
.ellipse3-b{
  position: absolute;
  width: 45px;
  height: 45px;
  right: -0.5rem;
  top: 30rem;
}
.ellipse4-b{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 54rem;
  left: 13rem;
}

.gold-biscuit{
    position: absolute;
    right: 3rem;
    top: 15rem;
    
}
}
@media screen and (max-width: 680px) {
  .body-sec {
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem;
    background: rgb(0, 0, 39);
    /* gap: 15rem; */
  }
  .body-r {
    flex-direction: column;
    gap: 3rem;
    margin-top: -15rem;
  }

  .box2 {
    position: static;
  }
  
  .line1 {
    background: url("../../images/line1-m.png");
    border: 1px dashed transparent;
    width: 298px;
    height: 206.95px;
    position: relative;
    top: 21rem;
    left: 47%;
    background-repeat: no-repeat;
  }
  .line2 {
    background: url("../../images/line2-m.png");
    /* border: 1px dashed transparent; */
    width: 298px;
    height: 206.95px;
    position: relative;
    left: 49%;
    top: 29rem;
    background-repeat: no-repeat;
  }
  .box p {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* or 200% */
  
    text-align: center;
    letter-spacing: 0.01em;
  
    color: rgba(255, 255, 255, 0.7);
    padding: 0 5rem;
  }
  .money-counting{
    position: relative;
    left: 12rem;
    top: 14rem;
    width: 47px;
height: 47px;
}

.paper-bag{
    position: relative;
    top: 37rem;
    left: -12rem;
    width: 61.72px;
height: 61.72px;

}

.ellipse1-b{
  position: absolute;
  width: 60px;
  height: 60px;
  left: 8rem;
  top: 20rem;
  display: none;
}
.ellipse2-b{
  position: absolute;
  width: 60px;
height: 60px;
right: 12rem;
top: 15rem;
display: none;
}
.ellipse3-b{
  position: absolute;
  width: 45px;
  height: 45px;
  right: -0.5rem;
  top: 30rem;
  display: none;
}
.ellipse4-b{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 54rem;
  left: 13rem;
  display: none;
}

.gold-biscuit{
    position: relative;
    right: -8rem;
    top: 57rem;
    
}
}
@media screen and (max-width:450px){
  .box p {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* or 200% */
  
    text-align: center;
    letter-spacing: 0.01em;
  
    color: rgba(255, 255, 255, 0.7);
    padding: 0 3rem;
  }
}

@media screen and (max-width:377px){
  .body-r {
    flex-direction: column;
    gap: 3rem;
    margin-top: -20rem;
  }
  .money-counting{
    position: relative;
    left: 11rem;
    top: 14rem;
    width: 47px;
height: 47px;
}

.paper-bag{
    position: relative;
    top: 43rem;
    left: -11rem;
    width: 61.72px;
height: 61.72px;

}
.gold-biscuit{
    position: relative;
    right: -8rem;
    top: 63rem;
    
}
.line1 {
  background: url("../../images/line1-m.png");
  border: 1px dashed transparent;
  width: 298px;
  height: 250.95px;
  position: relative;
  top: 20rem;
  left: 47%;
  background-repeat: no-repeat;
}
.line2 {
  background: url("../../images/line2-m.png");
  /* border: 1px dashed transparent; */
  width: 298px;
  height: 250.95px;
  position: relative;
  left: 49%;
  top: 30rem;
  background-repeat: no-repeat;
}
}
@media screen and (max-width:310px){
  .body-r {
    flex-direction: column;
    gap: 3rem;
    margin-top: -28rem;
  }
  .money-counting{
    position: relative;
    left: 9rem;
    top: 14rem;
    width: 37px;
height: 37px;
}

.paper-bag{
    position: relative;
    top: 43rem;
    left: -9rem;
    width: 50.72px;
height: 50.72px;

}
.gold-biscuit{
    position: relative;
    right: -7rem;
    top: 65rem;
    width: 50.72px;
    height: 50.72px;
    
}
.line1 {
  background: url("../../images/line1-m.png");
  border: 1px dashed transparent;
  width: 320px;
  height: 300.95px;
  position: relative;
  top: 23rem;
  left: 47%;
  background-repeat: no-repeat;
}
.line2 {
  background: url("../../images/line2-m.png");
  /* border: 1px dashed transparent; */
  width: 320px;
  height: 300.95px;
  position: relative;
  left: 49%;
  top: 32rem;
  background-repeat: no-repeat;
}
}