.testimonial{
    background: #F5F5F5;
    padding-top: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
}
.testimonial-heading>div>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 104.12%;

/* identical to box height, or 15px */
letter-spacing: 0.465em;
text-transform: uppercase;

color: #80D1FF;


}


.testimonial-heading>div>h3{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 81px;

/* identical to box height */
letter-spacing: 0.01em;

/* primary/3 */
color: #2E2B69;


}
.upper-testi{
   display: inline-grid;
   grid-template-columns: 1fr 1fr 1fr;
   
   padding: 106px 164px 5rem  176px;
   gap: 1rem;
}

.user-profile-1{
    width: 403px;
height: 423px;
}
.user-profile-2{
    width: 439px;
    height: 303px;
}
.user-profile-3{
    width: 268px;
    height: 382px;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.user-profile-3 .user-details{
    margin-top: 18px;
    margin-bottom: 0px;
}
.user-profile-4{
    width: 403px;
height: 192px;
}
.user-profile-5{
    width: 439px;
    height: 311px;
    position: relative;
    top: -7.5rem;
}
.user-profile-6{
    width: 222px;
height: 202px;
position: relative;
    top: -2.6rem;
    left: 2rem;
}
.upper-testi>div{
    border: 1px solid #E8E8EB;
border-radius: 16px;
box-sizing: border-box;
padding: 2rem;
}
.user{
    display: flex;
    align-items: center;
    gap: 24px;
}
.user .user-details{
    text-align: left;
}
.user-img{
    width: 64px;
height: 64px;
}
.user-img>img{
    width: 100%;
    height: 100%;
}
.user-details{
    display: flex;
    flex-direction: column;
}
.user-details .name>span{

font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 17px;
line-height: 104.12%;

/* identical to box height, or 19px */
letter-spacing: 0.245em;
text-transform: uppercase;

/* secondary/grey scale/1 */
color: #323549;


}

.user-details .profile>span{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 104.12%;

/* identical to box height, or 15px */
letter-spacing: 0.01em;

/* secondary/grey scale/6 */
color: #ADAEB6;


}

.statement>p{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 30px;
padding-top: 18px;
/* padding-bottom: 13px; */
/* or 188% */
letter-spacing: 0.01em;

/* secondary/grey scale/5 */
color: #9496A0;
margin-bottom: 0.3;

}

.star{
    width: 107px;
height: 18.45px;
}

.star>img{
    width: 100%;
    height: 100%;
}



.user-profile-6>img{
    width: 100%;
    height: 100%;
}


@media screen and (max-width:1200px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 1250px;
    }
    .upper-testi{
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr;
        
        padding: 106px 164px 5rem  164px;
        gap: 1rem;
     }
     
}
@media screen and (max-width:991px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 1150px;
    }
}
@media screen and (max-width:850px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 1100px;
    }
}
@media screen and (max-width:800px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 1050px;
    }
}
@media screen and (max-width:750px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 1000px;
    }
}
@media screen and (max-width:700px) {
 

    .testimonial-heading>div>span{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 12px;
line-height: 104.12%;

/* or 12px */
letter-spacing: 0.465em;
text-transform: uppercase;

color: #80D1FF;


    }
    .testimonial-heading>div>h3{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 38px;
line-height: 48px;

/* identical to box height */
text-align: center;
letter-spacing: 0.01em;

/* primary/3 */
color: #2E2B69;


    }
    .star{
        width: 83.16px;
height: 14.34px;
    }
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 950px;
        padding: 106px 164px 2rem  176px;
        /* scrollbar-color: transparent; */
    }
    /* .upper-testi {
        -ms-overflow-style: none;  
        scrollbar-width: none; 
    }
    .upper-testi::-webkit-scrollbar { 
        display: none; 
    } */
    .user-details .name>span{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 104.12%;

/* identical to box height, or 15px */
letter-spacing: 0.245em;
text-transform: uppercase;

/* secondary/grey scale/1 */
color: #323549;


    }

    .user-details .profile>span{
        font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 104.12%;

/* or 12px */
letter-spacing: 0.01em;

/* secondary/grey scale/6 */
color: #ADAEB6;


    }
    .statement>p{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;
        
        /* or 194% */
        letter-spacing: 0.01em;
        
        /* secondary/grey scale/5 */
        color: #9496A0;
        
        
    
    }
.user-profile-1{
    width: 341.19px;
    height: 340.49px;
}
.user-profile-2{
    width: 341px;
    height: 260px;
}
.user-profile-3{
    width: 238px;
    height: 340.49px;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.user-profile-3 .user-details{
    margin-top: 18px;
    margin-bottom: 0px;
}
.user-profile-4{
    width: 341px;
height: 152px;
}
.user-profile-5{
    width: 341px;
    height: 280px;
    position: relative;
    top: -5rem;
}
.user-profile-6{
    width: 202px;
height: 182px;
position: relative;
    top: -0rem;
    left: 1.2rem;
}
}
@media screen and (max-width:650px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 900px;
    }
}
@media screen and (max-width:600px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 850px;
    }
}
@media screen and (max-width:550px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 780px;
    }
}
@media screen and (max-width:500px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 760px;
    }
}
@media screen and (max-width:450px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 730px;
    }
}
@media screen and (max-width:400px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 700px;
    }
}
@media screen and (max-width:350px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 650px;
    }
}
@media screen and (max-width:300px) {
    .upper-testi{
        overflow-x: auto;
        position: relative;
        width: 600px;
    }
}